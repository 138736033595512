<template>
  <div class="ebp-dropzone">
    <file-pond
      class="mb-5"
      v-bind="opts"
      ref="pond"
      @updatefiles="handle"
      v-on="$listeners"
      :fileValidateTypeDetectType="getCustomMIMEType"
    />
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  name: "ebp-dropzone",
  props: {
    options: Object
  },
  components: {
    FilePond
  },
  computed: {
    opts() {
      return {
        allowMultiple: false,
        maxFiles: 1,
        ...this.options
      };
    }
  },
  mounted() {
    const filePondCreditsRemoveInterval = setInterval(() => {
      const filePondCredits = document.querySelector(".filepond--credits");
      if (filePondCredits) {
        clearInterval(filePondCreditsRemoveInterval);
        filePondCredits.remove();
      }
    }, 1000);
  },
  methods: {
    getCustomMIMEType: function getCustomMIMEType(source, type) {
      if (window.navigator.platform.indexOf("Win") > -1) {
        if (type === "application/vnd.ms-excel") type = "text/csv";
      }
      return Promise.resolve(type);
    },
    handle() {
      const files = this.$refs.pond.getFiles().map(f => f.file);
      this.$emit("input", files);
    }
  }
};
</script>

<style lang="scss">
/* .ebp-dropzone {
  .filepond--file {
    background: $primary;
    color: white;
  }
} */
</style>
